
.game-container{
    /* width: 100vw;
    height: 100vh; */
    background-color: rgb(148, 148, 141);
    /* position: relative;
    overflow: hidden; */
    position:absolute;
    overflow:hidden;
    top:0;   left:0;
    bottom:0; right:0;

}
.frame-container{
    width: 100%;
    height: 100%;
}

.progress-bar{
    position:absolute;
    overflow:hidden;
    top:0;   left:0;
    bottom:0; right:0;
}
.progress-bar span {
    position: absolute;
    top: 34.3vh;
    right: 5.6%;
    height: 2.35%;
    width: 3.2%;
    border: 1px solid white;
    background-color: #ff0000;
}


.game-container .starterFrame {
    width: 100%;
    height: 100%;
    object-fit: cover;

    z-index: -10;
}

.game-container .JMLogo {
    position: absolute;
    right: -4vw;
    bottom: -1vh;
    width: 40vw;
    transform: rotate(-10deg)
}
.game-content{
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    top: 0;
}
.game-content .hotorcold{
    position: relative;
    top: 15%;

}
.game-content .hotorcold .hot-parent{
    display: flex;
    justify-content: center;
    align-items: center;

}
.game-content .hotorcold .cold-parent{

    display: flex;
    justify-content: center;
    align-items: center;
}

.game-content .hotorcold .hot {
    position: relative;
    width: 45vw;

}
.game-content .hotorcold .cold {
    position: relative;
    width: 45vw;
  
}

.game-content .hotorcold .cold-loading{
    position: relative;
    width: 40.8vw;
    top: 1%;
}

.game-content .hotorcold .hot-loading{
    position: relative;
    width: 40vw;
    top: 0%;
}




.game-content-quest .quest-parent{
    position: absolute;
    top: -20%;
    left: 0%;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: growAnimation 1s;
}


.game-content-quest .quest-parent .quest{
    width: 60%;

}
.game-content-quest .quest-parent h5{
    position: absolute;
    font-size: 7.5vw;
    color: white;
    font-weight: 600;

}

.howtoplay .quest-parent{
    position: absolute;
    top: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: growAnimation 1s;
}

.howtoplay{
    opacity: 1;
}

.howtoplay .quest-parent .quest{
    width: 150%;
    transform: rotate(90deg);
}
.howtoplay .quest-parent .text-parent{
    width: 58%;
    position: absolute;
    color: white;
    text-align: center;
}

.howtoplay .quest-parent .text-parent p {
    font-size: 5vw;
    font-weight: 600;
    margin-block-start: 0;
    margin-top: 13%;
}
.hand {
    width: 9vw;
    position: relative;
    top: 0.8vh;
}
.howtoplay .quest-parent .text-parent p:first-child {
    font-size: 8.5vw;
    font-weight: 600;
    margin-block-end: 0.3em;
    margin-block-start: 0;

}

.hide{
    opacity: 0;
    transition: opacity 1s;
}



.gameplay .quest-parent-1{
    position: absolute;
    top: -40%;
    left: 0%;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: growAnimation 1s;
}

.gameplay .score {
    position: absolute;
    font-size: 5vw;
    color: white;
    left: -30%;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: growAnimation 1s;
}

.gameplay .quest-parent-1 .quest{
    width: 40%;
    height: 10%;

}
.gameplay .quest-parent-1 h5{
    position: absolute;
    font-size: 5.5vw;
    color: white;
    font-weight: 600;

}
.show {
    opacity: 1;
    transition: opacity 1s;
}
.gameplay .quest-parent-2{
    position: absolute;
    top: -39%;
    left: 0%;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: growAnimation 1s;
}


.gameplay .quest-parent-2 .quest{
    width: 35%;

}
.gameplay .quest-parent-2 h5{
    
    position: absolute;
    font-size: 10vw;
    color: white;
    margin-right: 0.7vw;
}



.congrat-container h3{
    text-align: center;
    color: red;
    -webkit-text-stroke: 1px #ffffff;
    font-size: 8.3vw;
    font-weight: 400;
    font-family: bernierregularo;

}
.congrat-container h2{
    text-align: center;
    color: white;
    font-size: 7vw;
    font-weight: 400;
}


.camera-allow-parent{
    top: 0;
    display: grid;
    color: white;
    justify-items: center;
    align-items: center;
    animation: growAnimation 1s;
    font-size: 5vw;
}

.camera-allow-parent h2{
    width: 60vw;
    text-align: center;
    margin-block-start: 0;
    
}
.camera-allow-parent img{
    width: 25vw;
    
}

.congrat-container .medal-container{
    position: absolute;
    bottom: 4%;
    display: flex;
    justify-content: center;
    z-index: 950;

}

.medal-container h2 {
    font-size: 18vw;
    font-family:distressed;
    position: absolute;
    bottom: 19%;
    right: 21%;
}

.congrat-container .cam-logo{
    width: 100vw;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom:15%;
    z-index: 950;
    
}
.congrat-container .goldM {
    width: 80%;
    height: 80%;
    position: absolute;
    
}
.congrat-container .cam-logo img{
    width: 20vw;
}

.congrat-container .medal-container img{
    width: 87%;

}

.congrat-container .best-score {
    position: absolute;
    width: 100vw;


}

.congrat-container .best-score h4{
    color: white;
    font-size: 8vw;
    text-align: center;
    margin-block-end: 0.2em;
}
.congrat-container .best-score h5{
    color: red;
    font-size: 8vw;
    text-align: center;
    margin-block-start: 0em;

}

.relative {
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}


.countdown-text{
    -webkit-text-stroke: 1px #ffffff;

}
