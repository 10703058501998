

/* =========== HEADER ===========  */
.starterFrame {
    width: 100%;
    height: 100%;
    object-fit: cover;

    z-index: -10;
}

.intro-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-color: #174a7d;
    box-shadow: rgba(0, 0, 0, 0.35) 0px -60vh 30vh -20vh inset;
    /* padding-top: 12%; */
}

.intro-video{

}

.game-container header {
    top: 8vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}


.game-container header .lines {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.game-container header .lines img {
    width: 80%;
    height: 50%;
    animation: growAnimation 1s;

}

.game-container header .jmlogo {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;

    position: absolute;
    top: -40%;
    animation: growAnimation 2.5s;

}

.game-container header .jmlogo img {
    transform: rotate(5deg);
    animation: rotateAnimation 2s infinite;

    width: 20vw;
}

.game-container header .lines .subtitle {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;

    position: absolute;
    position: absolute;
    top: 25%;
    width: 100%;
    animation: growAnimation 1s, waveAnimation 3s infinite;


}

.game-container header .lines .boardwalk {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;

    position: absolute;
    position: absolute;
    top: 77%;  

    animation: growAnimation 2.5s;
    width: 60%;
}




/* =========== CONTENT =========== */


.intro-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100vh;
    width: 100vw;



}
.intro-content .intro-button-parent h2 {
    color: white;
    text-align: center;
}
.intro-content .intro-button-parent .intro-button {
    border-radius: 50%;
    background-color: rgba(112, 250, 0, 0);
    border: 0;
    animation: growAnimation 1s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.intro-content .intro-button-parent .intro-button img {
    width: 35%;
    -webkit-filter: brightness(150%);

}

.intro-content .intro-button-parent .intro-button.wave-anim img {
    animation: waveAnimation 1s;
}

.loader {
    position: absolute;

    border: 10px solid #f3f3f32e;
    border-top: 10px solid #ffffff;
    border-radius: 50%;
    width: 10vw;
    height: 10vw;
    animation: spin 1s linear infinite;
  }



/* ========== FOOTER ========== */

.intro-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.intro-footer .intro-footer-shadow {
    background-color: rgb(0, 0, 0);
    position: absolute;
    top: 94%;
    height: 0%;
    width: 65%;
    box-shadow: 1px -1px 20px 10px rgba(0,0,0,1);
    -webkit-box-shadow: 1px -1px 20px 10px rgba(0,0,0,1);
    -moz-box-shadow: 1px -1px 20px 10px rgba(0,0,0,1);

}




@keyframes growAnimation {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }

  @keyframes rotateAnimation {
    0% {
        transform: rotate(5deg);

    }
    50% {
        transform: rotate(-5deg);

    }
    100% {
        transform: rotate(5deg);

    }
  }

  @keyframes waveAnimation {
    0% {
      transform: scale(1);
    }

    50% {
        transform: scale(0.9);
      }
    100% {
        transform: scale(1);
    }
}


@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 