.sandwich-container img {
    width: 100%;
}

.sandwich-container {
    width: 30%;
    position: absolute;

}


.bottom-ingredient{

}