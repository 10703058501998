.webcam-container{
  height: 75vh;
  width: 75vw;
  position: relative;
  overflow: hidden;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.webcam-container video, canvas {
  height: 100%;
  width: auto;
  position: relative;

}

.movable-medal-parent {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 100;
}
.movable-medal-parent img {
    position: absolute;
    width: 20%;
    z-index: 100;
}


.flash {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0;
    animation-duration: 1s;
    animation-timing-function: ease-in;
    z-index: 1000000;
  }
  
    .is-flashing {
      animation-name: flash;
    }
  
  
  @keyframes flash {
    0% {
      opacity: 1;
    }
    
    60% {
      opacity: .75;
    }
    
    100% {
      opacity: 0;
    }
  }

.center-some-testing{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.taptodownload-container {
  position: relative;
  top: 84vh;
  color: white;
  width: 100%;
  text-align: center;
  height: 100vh;
  width: 100vw;
}


.taptodownload-container h1{
  font-size: 9vw;
  margin-block-start: 0em;


}

.taptodownload-container img {
  position:relative;
  width: 50%;
}