

.cold-container{
    position: absolute;
    left: 0;
    top: -10vh;
    height: 110vh;
    width: 100vw;
    
}
.cold-container .ingredients-parent img{
    position: absolute;
    top: 17vh;
    width: 30%;
}

.wrong-ingr {
    width: 18% !important;
}


.cold-container .ingredients-parent .goDown{
    top: 110vh;
    transition: top 6s;
}

.ingredient {
    opacity: 1;
}

.catched {
    opacity: 0;
    transition: opacity 0.5s;
}

.plus10 {
    position: absolute;
    bottom: 300%;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 6.5vw;
    font-weight: bold;
    color: rgb(26, 190, 26);

}

.catchthebun {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 5vw;
}
.ingr-9{
    width: 20% !important;
}