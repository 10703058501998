@font-face {
  font-family: bernierregularo;
  src: url(./assets/BERNIERRegular-Regular.otf);
}


  @font-face {
    font-family: distressed;
    src: url(./assets/BERNIERDistressed-Regular.ttf);
  }

  p, h1, h2, h3, h4, h5, h6, div {
    font-family: bernierregularo;
  }